.feature {
    &__item {
        width: calc(100% / 5);
        margin-top: 1em;
        text-align: center;
        @media (--ipad) {
            width: calc(100% / 3);
        }
        @media (--ipad_mini) {
            width: calc(100% / 2);
        }
        @media (--phone) {
            width: calc(100% / 2);
        }

        &:hover {
            .feature__title {
                color: var(--color__brown);
            }
        }
    }
}

@each $i in customize, eco, fast, safe, trendy {
    .feature .feature__item:hover .feature__icon_$(i) {
        background-image: svg-inline($(i)_h);
    }
}