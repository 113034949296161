@define-mixin menu-hover {
    color: white !important;
    font-family: var(--family__Ebold);

    &:before {
        transform: scale(1);
    }
}

.site-header {
    #mainnav {
        width: 100%;

        .menu {
            &-item {
                position: relative;
                padding: 0;
                width: calc(100% / 5);
                font-size: .8rem;

                &.current-menu-item > a,
                &.current_page_item > a {
                    @mixin menu-hover;
                }

                &:hover > a {
                    @mixin menu-hover;
                }

                a {
                    float: none;
                    display: block;
                    padding: 2em 0;
                    text-align: center;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        z-index: -1;
                        width: 100%;
                        transform-origin: center bottom;
                        transform: scale(1, .05);
                        transition: transform .4s;
                    }
                }

                @for $i from 1 to 5 {
                    &:nth-child($(i)) > a {
                        @if $i == 1 {
                            color: var(--color__red);

                        } @else if $i == 2 {
                            color: var(--color__yellow);
                            
                        } @else if $i == 3 {
                            color: var(--color__cyan-blue);

                        } @else if $i == 4 {
                            color: var(--color__blue);

                        } @else if $i == 5 {
                            color: var(--color__purple);
                        }
                    }
                }

                @for $i from 1 to 5 {
                    &:nth-child($(i)) > a:before {
                        @if $i == 1 {
                            background-color: var(--color__red);

                        } @else if $i == 2 {
                            background-color: var(--color__yellow);
                            
                        } @else if $i == 3 {
                            background-color: var(--color__cyan-blue);

                        } @else if $i == 4 {
                            background-color: var(--color__blue);

                        } @else if $i == 5 {
                            background-color: var(--color__purple);
                        }
                    }
                }

                @for $i from 1 to 5 {
                    &:nth-child($(i)) > .sub-menu {
                        @if $i == 1 {
                            background-color: var(--color__red);

                        } @else if $i == 2 {
                            background-color: var(--color__yellow);
                            
                        } @else if $i == 3 {
                            background-color: var(--color__cyan-blue);

                        } @else if $i == 4 {
                            background-color: var(--color__blue);

                        } @else if $i == 5 {
                            background-color: var(--color__purple);
                        }
                    }
                }
            }
        }
    }
}