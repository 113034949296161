.banner {
    &__title {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: color(#2d2d2d a(.46));
        text-transform: uppercase;
        font: 2.5rem var(--family__light);
        color: white;
        align-items: center;
        justify-content: center;
    }
}