.shop {
    #secondary {
        float: left;
        @media (--ipad_mini) {
            float: none;
            width: 100%;
        }
        @media (--phone) {
            float: none;
            width: 100%;
        }
    }

    #primary {
        float: right;
        @media (--ipad_mini) {
            float: none;
            width: 100%;
        }
        @media (--phone) {
            float: none;
            width: 100%;
        }
    }
    
    .products {
        margin-top: 2em !important;
    }
}