.quality {
    &__icon {
        display: inline-block;
        margin-right: 5px;
        font-size: 26px !important;
        color: white;
        vertical-align: text-top;
        @media (--phone) {
            vertical-align: middle;
        }
    }
}