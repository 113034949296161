.feature {
    &__icon {
        width: 90px;
        height: 75px;
        margin: 0 auto;
        @media (--phone) {
            width: 100px;
            height: 80px;
        }
    }

}

@each $i in customize, eco, fast, safe, trendy {
    .feature__icon_$(i) {
        background: svg-inline($i) center/contain no-repeat;
    }
}