.site-header {
    .sub-menu {
        left: 0 !important;
        width: 300px !important;
        padding-top: 0 !important;
        @media (--desktop) {
            border-bottom-left-radius: 10px !important;
            border-bottom-right-radius: 10px !important;
        }
        @media (--ipad) {
            width: 100% !important;
        }
        @media (--ipad_mini) {
            width: 100% !important;
        }
        @media (--phone) {
            width: 100% !important;
        }

        &:after {
            display: none;
        }

        .menu {
            &-item {
                width: 50% !important;
                @media (--ipad) {
                    width: 100% !important;
                }
                @media (--ipad_mini) {
                    width: 100% !important;
                }
                @media (--phone) {
                    width: 100% !important;
                }

                > a {
                    width: 90% !important;
                    display: block;
                    margin: 0 auto;
                    background-color: transparent !important;
                    border-top-color: color(white a(.4)) !important;
                    font-size: 12px !important;
                    line-height: 1.2 !important;
                    color: white !important;
                    @media (--ipad) {
                        height: 100%;
                        background-color: var(--color__gray) !important;
                        text-align: center;
                    }
                    @media (--ipad_mini) {
                        height: 100%;
                        background-color: var(--color__gray) !important;
                        text-align: center;
                    }
                    @media (--phone) {
                        height: 100%;
                        background-color: var(--color__gray) !important;
                        text-align: center;
                    }

                    &:before {
                        display: none;
                    }

                    &:hover {
                        font-weight: bold !important;
                    }
                }
            }
        }
    }
}