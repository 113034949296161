.social {
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    border-radius: 50%;
    background-color: #808184;
    text-align: center;

    &:before {
        color: white;
    }

    &:hover {
        background-color: var(--color__yellow);
    }
}