.btn {
    &_color_cyan-blue {
        > .vc_btn3 {
            background-color: var(--color__cyan-blue) !important;

            &:hover {
                color: var(--color__cyan-blue) !important;
            }
        }
    }
}