.shop-product {
    @media (--phone) {
        .content-wrapper {
            padding-left: 1em !important;
            padding-right: 1em !important;
        }
    }
    
    .product_title {
        font: 2rem var(--family__Elight) !important;
        color: var(--color__text);
        @media (--ipad_mini) {
            padding-top: 1em;
        }
    }

    .woocommerce-product-gallery__wrapper {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 10%;
            left: 0;
            width: 95%;
            height: 100%;
            background-color: var(--color__cyan-blue);
        }

        .wp-post-image {
            width: 95% !important;
            margin-left: auto;
        }

        .woocommerce-product-gallery__image {
            position: relative;
            z-index: 2;
        }
    }

    .woocommerce-product-details__short-description {
        text-align: justify;
        font: .8rem/1.6 var(--family__regular);

        > p {
            margin-bottom: 0;
        }
    }

    .vc_section {
        padding: 0;
    }
}