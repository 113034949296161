.woocommerce {
    .products {
        .product {
            padding-top: 1em !important;
            background-color: #83ccc0;
            transition: background-color .5s;

            &:hover {
                background-color: var(--color__cyan-blue);

                .woocommerce-loop-category__title {
                    font-family: var(--family__Ebold) !important;
                }

                > a:after {
                    opacity: 1;
                    transform: translateY(0);
                    transition: opacity .4s cubic-bezier(.5, .1, .5, .9) .2s,
                    transform .5s;
                }
            }

            > a {
                display: block;
                overflow: hidden;
                position: relative;

                &:after {
                    content: 'READ MORE';
                    display: flex;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: calc(100% - 4em);
                    background-color: color(black a(.7));
                    color: var(--color__cyan-blue);
                    font: .8rem var(--family__regular);
                    justify-content: center;
                    align-items: center;
                    opacity: 0;
                    transform: translateY(-50%);
                }
            }
        }

        img {
            height: 148px !important;
            margin-bottom: 0 !important;
            object-fit: cover;
        }

        .woocommerce-loop-product__title,
        .woocommerce-loop-category__title {
            display: flex;
            height: 4em;
            padding: 0 1em !important;
            font: .8rem var(--family__regular) !important;
            color: white;
            justify-content: center;
            align-items: center;
        }
    }
}