.wpcf7 {
    input {
        &[type="text"],
        &[type="tel"],
        &[type="email"] {
            width: calc(100% / 3 - 1%);
            height: 36px;
            @mixin form-input;
            @media (--phone) {
                width: 100%;
                margin-top: 1em;
            }
        }

        &[type="tel"],
        &[type="email"] {
            margin-left: calc(3% / 2);
            @media (--phone) {
                margin-left: 0;
            }
        }

        &[type="submit"] {
            padding: .2em 2em;
            margin-top: 2em;
            background-color: var(--color__yellow);
            border-radius: 1em;
            font: .75rem var(--family__regular);
            color: white;

            &:hover {
                background-color: transparent;
                color: var(--color__yellow);
            }
        }
    }

    textarea {
        margin-top: 1.1em;
        @mixin form-input;
        resize: none;
    }
}