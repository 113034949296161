.widget_product_categories {
    .cat-item {
        padding: 3px 0 !important;

        + .cat-item {
            border-top: 1px solid var(--color__cyan-blue);
        }

        > a {
            display: block;
            padding: 0 .5em;
            font: .7rem/3em var(--family__regular);
            color: var(--color__cyan-blue) !important;
            transition: background-color .5s;
        }

        &.current-cat > a,
        > a:hover {
            background-color: #f0f1f1;
            font-family: var(--family__bold);
        }
    }
}