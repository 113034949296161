.footer-widgets {
    position: relative;
    padding: 3em 0;
    background: url('../images/foot_widgets_bg@1x.jpg') center/cover no-repeat;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: color(#2f6c76 a(.9));
    }
}