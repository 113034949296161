.slogan {
    &__wrap {
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        height: var(--slogan__height);
        padding: .5em;
        background-color: color(#2d2d2d a(.65));
        place-content: center;
    }
}