:root {
    --color__red: #f95150;
    --color__yellow: #fbc92d;
    --color__cyan-blue: #00d1b7;
    --color__blue: #5c9ae5;
    --color__purple: #8c40dd;
    --color__brown: #d38753;
    --color__text: #58595b;

    --color__feature: #a6a8ab;

    --family__regular: "RRegular", serif;
    --family__light: "RLight", serif;
    --family__Elight: "RELight", serif;
    --family__bold: "RBold", serif;
    --family__Ebold: "REBold", serif;

    --slogan__height: 16rem;
}

@each $x in customize, eco, fast, safe, trendy {
    @svg-load $x url(../images/feature_$(x).svg) {
        fill: var(--color__feature);
    }
}

@each $x in customize, eco, fast, safe, trendy {
    @svg-load $(x)_h url(../images/feature_$(x).svg) {
        fill: var(--color__brown);
    }
}

@define-mixin title-sub {
    padding-bottom: .5em;
    font: .7rem var(--family__bold);
    color: #00d1b7;
}

@define-mixin form-input $bg: white {
    background-color: $bg;
    border: none;
    border-radius: 1.5em;
    font: .75rem var(--family__regular);
    color: black;

    &:focus {
        outline: 0;
    }

    &::placeholder {
        opacity: 1;
        color: black;
    }
}