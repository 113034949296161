.shop-related {
    background-color: #f0f1f1;

    .products {
        margin-bottom: 0;

    }

    .related.products {
        .products.columns-5 {
            .product {
                width: calc((100% - 2% * 4) / 5) !important;
                margin-right: 2%;
                @media (--ipad_mini) {
                    width: calc((100% - 2% * 2) / 3) !important;
                }
                @media (--phone) {
                    float: none;
                    width: 100% !important;
                    margin-right: 0;
                }

                &:nth-child(5n) {
                    margin-right: 0;
                    @media (--ipad_mini) {
                        margin-right: 2%;
                    }
                }

                @media (--ipad_mini) {
                    &:nth-child(3n) {
                        margin-right: 0;
                    }
                }
            }
        }

        > h2 {
            margin-bottom: 0;
            padding-bottom: 1em;
            text-transform: capitalize;
            font: 1rem var(--family__Elight);
        }

        .product {
            padding-top: 0 !important;
            background-color: transparent;

            > a {
                overflow: visible;
            }

            &:hover {
                .woocommerce-LoopProduct-link {
                    overflow: hidden;
                    
                    &:after {
                        display: none;
                    }
                }

                .woocommerce-loop-product__title {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    height: 100%;
                    background-color: color(black a(.7));
                    color: var(--color__cyan-blue);
                }
            }
        }
        
        .woocommerce-loop-product__title {
            position: absolute;
            top: 100%;
            width: 100%;
            color: var(--color__text);
        }
    }

}