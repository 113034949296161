.wpcf7 {
    input {
        &[type="text"],
        &[type="tel"],
        &[type="email"] {
            @mixin form-input color(white a(.73));
        }
    }

    textarea {
        @mixin form-input color(white a(.73));
    }
}