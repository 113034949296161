.quality {
    &__item {
        overflow: hidden;
        max-height: 39px;
        padding: .2em .5em;
        background-color: #25d366;
        transform: translateX(70%);
        transition: transform .4s,
        max-height .2s;
        
        + .quality__item {
            margin-top: 1em;
        }

        &:hover {
            max-height: 15em;
            transform: translateX(0);
        }
    }
}