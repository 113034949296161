.btn-menu {
    @media (--ipad) {
        line-height: 1;
    }
    @media (--ipad_mini) {
        line-height: 1;
    }
    @media (--phone) {
        margin-top: 0;
        margin-right: 10px;
        line-height: 1;
    }
}