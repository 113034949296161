.woocommerce .count,
.woocommerce .woocommerce-breadcrumb,
.woocommerce .archive-title,
.woocommerce .woocommerce-result-count,
.woocommerce .woocommerce-ordering,
.woocommerce .button,
.shop-product .product_meta,
.shop-product .price,
.shop-product .tabs.wc-tabs,
.shop-product .woocommerce-Tabs-panel > h2,
.shop-product .related.products,
.shop-product .woocommerce-Tabs-panel.woocommerce-Tabs-panel--reviews,
.header-clone {
    display: none !important;
    width: 0;
    height: 0;
    visibility: hidden;
    font-size: 0;
}