@define-mixin font $name, $fileName {
    @font-face {
        font-family: $name;
        src: url('../fonts/$(fileName).eot'); /* IE9+ */
        src: url('../fonts/$(fileName).eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
            url('../fonts/$(fileName).woff') format('woff'), /* chrome、firefox */
            url('../fonts/$(fileName).woff2') format('woff2'), 
            url('../fonts/$(fileName).ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
            url('../fonts/$(fileName).svg#fontname') format('svg'); /* iOS 4.1- */
    }
}