.btn {
    > .vc_btn3 {
        padding: .4em 1.4em;
        border-radius: 1em;
        border: none;
        background-image: none;
        background-color: var(--color__yellow) !important;
        font: .65rem var(--family__regular);
        color: white !important;

        &:hover {
            border: none;
            background-color: transparent !important;
            color: var(--color__yellow) !important;
        }
    }
}