.display-posts-listing {
    display: grid;
    list-style-type: none;
    grid-gap: .6em;
    grid-template-columns: .9fr .9fr 1.1fr 1.1fr;
    grid-template-rows: 7em 8em 9em;
    @media (--phone) {
        grid-gap: .4em;
    }
    
    .listing-item {
        position: relative;
        overflow: hidden;

        &:first-child {
            grid-area: 1 / 1 / 3 / 3;
        }

        &:nth-child(4) {
            grid-area: 3 / 1 / 4 / 3;
        }

        &:nth-child(5) {
            grid-area: 2 / 3 / 4 / 5;
        }

        .wp-post-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform .5s;
        }

        .title {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1em;
            background-color: color(#5c9ae5 a(.7));
            color: white;
            font: 1rem var(--family__regular);
            align-items: end;
            opacity: 0;
            transition: opacity 1s;
        }

        a {
            pointer-events: none;
        }

/*         &:hover {
            .wp-post-image {
                transform: scale(1.5) rotate(10deg);
            }

            .title {
                opacity: 1;
            }
        } */
    }
}