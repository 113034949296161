.site-header {
    position: absolute;
    width: auto;
    background-color: white;
    @media (--desktop) {
        left: 50%;
        padding: 0 1em;
        border-bottom-right-radius: 1em;
        border-bottom-left-radius: 1em;
        transform: translateX(-50%);
    }
    @media (--ipad) {
        width: 100%;
    }
    @media (--ipad_mini) {
        width: 100%;
    }
    @media (--phone) {
        width: 100%;
    }

    .col-md-8 {
        @media (--phone) {
            position: static;
        }
    }
}