.site-header {
    #mainnav-mobi {
        left: 0;

        .menu {
            &-item {
                border: none;

                @for $i from 1 to 5 {
                    &:nth-child($(i)) > a {
                        @if $i == 1 {
                            background-color: var(--color__red);
                        } @else if $i == 2 {
                            background-color: var(--color__yellow);

                        } @else if $i == 3 {
                            background-color: var(--color__cyan-blue);

                        } @else if $i == 4 {
                            background-color: var(--color__blue);

                        } @else if $i == 5 {
                            background-color: var(--color__purple);

                        }
                    }
                }
            }
        }
    }
}